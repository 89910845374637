import React, { useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  rtColors,
  RtAccordionHeader,
  RtAccordionBody,
  RtIcon,
} from '@ritualco/ritual-frontend-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import Button from '@artemis/components/Button';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { FULFILLMENT_GUEST_STATUS } from '@artemis/utils/constants';
import { Basket } from './Basket';
import { GuestShape } from '../propTypes';

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${props => props.theme.palette.grey['200']};
  margin: 0;
`;

const AccordionHeader = styled(RtAccordionHeader)`
  margin: 0 6px;
  padding: 12px 0;
  & > div {
    padding: 0;
  }
  & > div + div {
    margin-left: 12px;
  }
`;

const GuestWrapper = styled.div`
  margin: 0 6px;
  padding: 12px 0;
  margin-right: 42px;
`;

const GuestDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GuestInfo = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const GuestName = styled.p`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const GuestStatus = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black800};
  line-height: 20px;
`;

const GuestAction = styled.div`
  display: flex;
`;

const Avatar = styled.img`
  border-radius: 100%;
  margin-right: 12px;
  object-fit: cover;
  height: 44px;
  width: 44px;
`;

const ActionButton = styled(Button)`
  min-width: 0;
  padding: 0;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
`;

const ActionLink = styled.a`
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  color: ${props => props.theme.palette.primary};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const SEPARATOR = ' • ';

const getMessageForStatus = ({ isHost, status }) => {
  switch (status) {
    case 'ADMIN_NOT_JOINED':
      return 'groupOrder.host';
    case FULFILLMENT_GUEST_STATUS.READY:
      return 'groupOrder.confirmed';
    default:
      return isHost ? 'groupOrder.host' : 'groupOrder.browsing';
  }
};

const GuestSectionProps = {
  canRemoveSelf: PropTypes.bool,
  guest: GuestShape.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  isOrderSubmitted: PropTypes.bool.isRequired,
  merchantMenuUrl: PropTypes.string,
  onJoinAsGuest: PropTypes.func,
  onRemoveGuest: PropTypes.func,
};
export const GuestSection = ({
  canRemoveSelf = true,
  guest,
  isCurrentUser,
  isOrderSubmitted,
  merchantMenuUrl,
  onJoinAsGuest,
  onRemoveGuest,
}) => {
  const numItems = guest?.basket?.menuItems?.length || 0;

  const Action = useMemo(() => {
    const showRemoveGuest =
      !!onRemoveGuest &&
      guest.status !== 'ADMIN_NOT_JOINED' &&
      !isOrderSubmitted;
    const RemoveButton = (
      <ActionButton
        onClick={e => {
          e.stopPropagation();
          onRemoveGuest(guest.externalUserId);
        }}
      >
        <ResponsiveImage id="icons.trash.img" />
      </ActionButton>
    );
    const JoinButton = (
      <ActionButton onClick={onJoinAsGuest}>
        <FormattedMessage entry="groupOrder.joinAsGuest" />
      </ActionButton>
    );
    const AddItemsLink = (
      <ActionLink href={merchantMenuUrl}>
        <FormattedMessage entry="groupOrder.addItems" />
      </ActionLink>
    );

    if (!isCurrentUser) {
      return showRemoveGuest ? RemoveButton : null;
    }
    const canJoinAsGuest = !isOrderSubmitted && !numItems && !!onJoinAsGuest;
    if (canJoinAsGuest) {
      return JoinButton;
    }

    const showAddItems =
      guest.status === FULFILLMENT_GUEST_STATUS.BROWSING && merchantMenuUrl;
    if (showAddItems) {
      return AddItemsLink;
    }

    if (showRemoveGuest && canRemoveSelf) {
      return RemoveButton;
    }

    return null;
  }, [
    canRemoveSelf,
    guest.externalUserId,
    guest.status,
    isCurrentUser,
    isOrderSubmitted,
    merchantMenuUrl,
    numItems,
    onJoinAsGuest,
    onRemoveGuest,
  ]);

  const getStatus = () => {
    // If the user is a host with nothing in basket, just render "Host"
    if (guest.isHost && guest.basket?.menuItems?.length === 0) {
      return <FormattedMessage entry="groupOrder.host" />;
    }
    return (
      <>
        {!isOrderSubmitted && (
          <FormattedMessage entry={getMessageForStatus(guest)} />
        )}
        {!isOrderSubmitted && SEPARATOR}
        <FormattedMessage
          entry={numItems ? 'groupOrder.numItems' : 'groupOrder.noItems'}
          values={{ count: numItems }}
        />
        {isOrderSubmitted && ' '}
        {isOrderSubmitted && (
          <FormattedMessage
            entry="groupOrder.partOfOrder"
            values={{ number: guest.basket?.partNumber }}
          />
        )}
      </>
    );
  };

  const displayGuest = () => (
    <GuestDisplay>
      <GuestInfo>
        <Avatar
          src={guest.profilePictureUrl}
          alt={<FormattedMessage entry="groupOrder.profilePicture" />}
        />
        {guest.status === 'READY' && (
          <CheckIcon
            sx={{
              backgroundColor: rtColors.teal500,
              borderRadius: '100%',
              bottom: 0,
              color: 'white',
              fontSize: 14,
              left: '26px',
              padding: '2px',
              position: 'absolute',
            }}
          />
        )}
        <div>
          <GuestName>
            {`${guest.firstName} ${guest.lastInitial}`}
            {isCurrentUser && ' '}
            {isCurrentUser && <FormattedMessage entry="groupOrder.you" />}
          </GuestName>
          <GuestStatus>{getStatus()}</GuestStatus>
        </div>
      </GuestInfo>
      <GuestAction>{Action}</GuestAction>
    </GuestDisplay>
  );
  return (
    <>
      <Divider />
      {numItems ? (
        <>
          <AccordionHeader
            arrowComponent={
              <RtIcon name="Rt-Down" colour={rtColors.black700} size={24} />
            }
            arrowLocation="right"
            arrowRotation="180"
          >
            {displayGuest()}
          </AccordionHeader>
          <RtAccordionBody>
            {guest.basket && <Basket basket={guest.basket} />}
          </RtAccordionBody>
        </>
      ) : (
        <GuestWrapper>{displayGuest()}</GuestWrapper>
      )}
    </>
  );
};

GuestSection.propTypes = GuestSectionProps;
