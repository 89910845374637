import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '@artemis/utils/currency-format';
import { BasketShape } from '../propTypes';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BasketContainer = styled.div`
  margin: 0 6px;
  padding-bottom: 12px;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 44px auto;
  grid-column-gap: 14px;
  justify-items: start;
  padding: 12px 0;
`;

const Quantity = styled.div`
  ${props => props.theme.typography.caption};
  align-self: start;
  background-color: ${props => props.theme.rtColors.background700};
  border-radius: 4px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  line-height: 24px;
  text-align: center;
  width: 24px;
`;

const ItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  justify-self: stretch;
`;

const ItemName = styled.p`
  ${props => props.theme.typography.bodySmall};
  line-height: 20px;
`;

const ItemOptions = styled(ItemName)`
  color: ${props => props.theme.rtColors.black700};
`;

const ItemNotes = styled(ItemOptions)`
  font-style: italic;
`;

const ItemPrice = styled.div`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black900};
`;

const BasketProps = {
  basket: BasketShape.isRequired,
};
export const Basket = ({ basket }) => (
  <BasketContainer>
    {basket.menuItems.map(item => (
      <Item key={item.menuItemId}>
        <Quantity>{item.quantity}</Quantity>
        <ItemDetails>
          <Flex>
            <ItemName>{item.name}</ItemName>
            {item.options && (
              <ItemOptions>
                {item.options.map(({ name }) => name).join(', ')}
              </ItemOptions>
            )}
            {item.notes && <ItemNotes>{item.notes}</ItemNotes>}
          </Flex>
          <ItemPrice>
            {formatCurrency({
              currencyCode: basket.currencyCode,
              value: item.finalPriceMicro,
            })}
          </ItemPrice>
        </ItemDetails>
      </Item>
    ))}
  </BasketContainer>
);

Basket.propTypes = BasketProps;
