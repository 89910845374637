import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import ScheduledForTime from '@artemis/components/ScheduledForTime';
import { TimeRange } from '@artemis/components/TimeRange';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { openModal } from '@artemis/store/fulfillment/slice';
import { INTERVALS, PAYMENT_SPLIT_TYPE } from '@artemis/utils/constants';
import { PaymentSplitShape } from '@artemis/components/GroupOrder/propTypes';
import PaymentSplitLabel from '@artemis/components/GroupOrder/PaymentSplitLabel';
import getTimeRange from './getTimeRange';
import { GroupOrderInfoShape } from './propTypes';

const MAX_GRANULAR_MINUTES = 60;
const SEPARATOR = ' \u2022 ';

const OrderInfoLine = styled.div`
  display: flex;
  margin-bottom: 6px;
  & > p {
    ${props => props.theme.typography.bodySmall};
  }
`;

const Link = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  text-decoration: none;
`;

const LinkButton = styled.button`
  ${props => props.theme.typography.bodySmall};
  background: none;
  border: 0;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding: 0;
  :hover {
    text-decoration: underline;
  }
`;

const Icon = styled(ResponsiveImage)`
  flex: 0 0 auto;
  height: 21px;
  padding-right: 10px;
  width: 14px;
`;

const InfoSection = ({
  isDelivery,
  isDineIn,
  isInstoreOrder,
  isGroupOrder,
  groupOrderInfo,
  orderLaterOnly,
  merchantAddress,
  merchantMapLink,
  deliveryAddress,
  pickupEstimate,
  scheduledForTime,
  deliveryTimeRange,
  paymentSplit,
  currencyCode,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const isScheduled = !!scheduledForTime;
  const pickupEstimateMinutes =
    pickupEstimate / INTERVALS.MINUTE_IN_MILLISECONDS;
  const generateMessages = () => {
    const orderNowMessage = isGroupOrder
      ? { entry: 'menu.asSoonAsPossible' }
      : { entry: 'global.orderNow' };
    const scheduleOrderMessage = { entry: 'menu.schedule.scheduleOrder' };
    if (isDelivery) {
      const address = readOnly ? (
        deliveryAddress
      ) : (
        <LinkButton onClick={() => dispatch(openModal())}>
          {deliveryAddress}
        </LinkButton>
      );

      return {
        addressMessage: {
          entry: 'global.deliveryTo',
          values: {
            address,
          },
        },
        timingMessage: orderLaterOnly ? scheduleOrderMessage : orderNowMessage,
      };
    }
    const merchantAddressLink = (
      <Link href={merchantMapLink}>{merchantAddress}</Link>
    );
    const readyTimeEstimateMessage = {
      entry:
        pickupEstimateMinutes > MAX_GRANULAR_MINUTES
          ? 'cart.pickupAfterAnHour'
          : 'cart.pickupTime',
      values:
        pickupEstimateMinutes > MAX_GRANULAR_MINUTES
          ? { value: pickupEstimateMinutes }
          : getTimeRange(pickupEstimateMinutes),
    };
    const getTimingMessage = pickupEstimate
      ? readyTimeEstimateMessage
      : orderNowMessage;

    // For guests in a pickup group order, indicate the host will pickup
    const pickupMessage =
      isGroupOrder && !groupOrderInfo?.isAdmin
        ? {
            entry: 'groupOrder.hostPickingUpOrder',
            values: { name: groupOrderInfo?.hostName },
          }
        : {
            entry: 'global.pickupFrom',
            values: {
              address: merchantAddressLink,
            },
          };

    /**
     * Note the order here is important: isInstoreOrder
     * takes precedence over isDineIn
     */
    if (isInstoreOrder) {
      return {
        addressMessage: pickupMessage,
        timingMessage: orderLaterOnly ? scheduleOrderMessage : getTimingMessage,
      };
    }

    const dineInMessage = {
      entry: 'global.dineInAt',
      values: {
        address: merchantAddressLink,
      },
    };

    return {
      addressMessage: isDineIn ? dineInMessage : pickupMessage,
      timingMessage: orderLaterOnly ? scheduleOrderMessage : getTimingMessage,
    };
  };

  const { addressMessage, timingMessage } = generateMessages();
  return (
    <>
      <OrderInfoLine>
        <Icon id="cart.location.img" />
        <p>
          <FormattedMessage {...addressMessage} />
        </p>
      </OrderInfoLine>
      {!isDineIn && (
        <OrderInfoLine>
          <Icon id="cart.time.img" />
          <p>
            {isScheduled ? (
              <ScheduledForTime scheduledForTime={scheduledForTime} />
            ) : (
              <>
                <FormattedMessage {...timingMessage} />
                {isDelivery && !orderLaterOnly && deliveryTimeRange && (
                  <>
                    {SEPARATOR}
                    <FormattedMessage entry="delivery.timeRangeEstimate" />
                    <TimeRange
                      min={deliveryTimeRange.min}
                      max={deliveryTimeRange.max}
                    />
                  </>
                )}
              </>
            )}
          </p>
        </OrderInfoLine>
      )}
      {paymentSplit?.paymentType === PAYMENT_SPLIT_TYPE.FIXED_AMOUNT &&
        currencyCode && (
          <OrderInfoLine>
            <Icon id="cart.payment.img" />
            <p>
              <PaymentSplitLabel
                paymentSplit={paymentSplit}
                currencyCode={currencyCode}
              />
            </p>
          </OrderInfoLine>
        )}
    </>
  );
};

InfoSection.propTypes = {
  isDelivery: PropTypes.bool,
  isDineIn: PropTypes.bool,
  isInstoreOrder: PropTypes.bool,
  isGroupOrder: PropTypes.bool,
  groupOrderInfo: GroupOrderInfoShape,
  orderLaterOnly: PropTypes.bool,
  merchantAddress: PropTypes.string,
  merchantMapLink: PropTypes.string,
  deliveryAddress: PropTypes.string,
  pickupEstimate: PropTypes.number,
  scheduledForTime: PropTypes.shape({
    date: PropTypes.string,
    isToday: PropTypes.bool,
    isTomorrow: PropTypes.bool,
  }),
  deliveryTimeRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  paymentSplit: PaymentSplitShape,
  currencyCode: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default InfoSection;
