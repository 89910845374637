import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import StandardModal from '@artemis/components/StandardModal';
import {
  getFulfillmentDetails,
  getFulfillmentPaymentSplit,
  getIsDetailsModalOpen,
  getIsLeaveModalOpen,
  getIsOrderSubmitted,
  getFulfillmentGuests,
  getFulfillmentId,
  getIsCurrentUserAdmin,
} from '@artemis/store/groupOrder/selectors';
import {
  setDetailsModal,
  setLeaveModal,
} from '@artemis/store/groupOrder/slice';
import InfoSection from '@artemis/components/Cart/InfoSection';
import {
  getCurrencyCode,
  getMerchantId,
  getMerchantMapLink,
  getMerchantStreetAddress,
} from '@artemis/store/merchant/selectors';
import {
  getFullDeliveryAddress,
  getScheduledForTime,
} from '@artemis/store/cart/selectors';
import GuestList from '@artemis/components/GroupOrder/GuestList';
import { getUserExternalId } from '@artemis/store/user/selectors';
import { logGroupOrderDetailsModalImpression } from './analytics';

const Title = styled.h2`
  ${props => props.theme.typography.h6}
  font-weight: ${props => props.theme.typography.fontWeightBold};
  padding-bottom: 1rem;
`;

const StyledModal = styled(StandardModal)`
  ${props => props.theme.isTablet`
    height: 738px;
  `}
`;

const Content = styled.div`
  padding-bottom: 45px;
`;

const GuestSection = styled.div`
  margin-top: 2.5rem;
`;

const GuestCount = styled.p`
  ${props => props.theme.typography.bodySmall};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  margin-bottom: 0.75rem;
`;

const GroupOrderDetailsModal = () => {
  const fulfillmentId = useSelector(getFulfillmentId);
  const fulfillment = useSelector(getFulfillmentDetails);
  const isOrderSubmitted = useSelector(getIsOrderSubmitted);
  const guests = useSelector(getFulfillmentGuests);
  const isOpen = useSelector(getIsDetailsModalOpen);
  const userExternalId = useSelector(getUserExternalId);
  const merchantAddress = useSelector(getMerchantStreetAddress);
  const merchantMapLink = useSelector(getMerchantMapLink);
  const deliveryAddress = useSelector(getFullDeliveryAddress);
  const scheduledTime = useSelector(getScheduledForTime);
  const currencyCode = useSelector(getCurrencyCode);
  const paymentSplit = useSelector(getFulfillmentPaymentSplit);
  const isLeaveModalOpen = useSelector(getIsLeaveModalOpen);
  const merchantId = useSelector(getMerchantId);
  const isAdmin = useSelector(getIsCurrentUserAdmin);

  const dispatch = useDispatch();

  const onLeave = () => {
    dispatch(setLeaveModal({ isOpen: true }));
  };

  const onClose = () => {
    dispatch(setDetailsModal({ isOpen: false }));
  };

  useEffect(() => {
    if (isOpen && fulfillmentId) {
      logGroupOrderDetailsModalImpression({
        merchantId,
        fulfillmentId,
      });
    }
  }, [isOpen, fulfillmentId]);

  if (!userExternalId || !fulfillment?.groupDisplay) {
    return null;
  }

  const { name } = fulfillment.groupDisplay;

  return (
    <StyledModal
      fixedControls
      isOpen={isOpen && !isLeaveModalOpen}
      onClickSecondaryButton={onLeave}
      onClose={onClose}
      secondaryButtonText={
        <FormattedMessage entry="groupOrder.detailsModal.leaveButton" />
      }
    >
      <Title>
        <FormattedMessage
          entry="groupOrder.detailsModal.title"
          values={{ name }}
        />
      </Title>
      <Content>
        <InfoSection
          isDelivery={fulfillment.fulfillmentType === 'DELIVERY'}
          isInstoreOrder={fulfillment.fulfillmentType === 'IN_STORE'}
          isGroupOrder
          groupOrderInfo={{
            isAdmin,
            hostName: name,
          }}
          merchantAddress={merchantAddress}
          merchantMapLink={merchantMapLink}
          deliveryAddress={deliveryAddress}
          scheduledForTime={scheduledTime}
          paymentSplit={paymentSplit}
          currencyCode={currencyCode}
          readOnly
        />

        <GuestSection>
          <GuestCount>
            <FormattedMessage
              entry="groupOrder.guestsCount"
              values={{
                currentGuests: guests.length,
                maxGuests: fulfillment.guestMax,
              }}
            />
          </GuestCount>
          <GuestList
            currentUserExternalId={userExternalId}
            isOrderSubmitted={isOrderSubmitted}
            guests={guests}
          />
        </GuestSection>
      </Content>
    </StyledModal>
  );
};

export default GroupOrderDetailsModal;
