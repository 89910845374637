// The logic here is provided by BE, which is suppose to match the logic
// that's used on the marketplace app
const getMinTime = timeInMinutes => {
  if (timeInMinutes < 5) {
    return 2;
  }
  if (timeInMinutes <= 60) {
    return Math.floor(timeInMinutes / 5) * 5;
  }
  return 0; // This should never be seen
};
const getMaxTime = timeInMinutes => {
  if (timeInMinutes < 5) {
    return 5;
  }
  if (timeInMinutes < 10) {
    return 8;
  }
  if (timeInMinutes <= 60) {
    return (Math.floor(timeInMinutes / 5) + 1) * 5;
  }
  return 0; // This should never be seen
};

const getTimeRange = timeInMins => ({
  minTime: getMinTime(timeInMins),
  maxTime: getMaxTime(timeInMins),
});

export default getTimeRange;
