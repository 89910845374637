import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { PAYMENT_SPLIT_TYPE } from '@artemis/utils/constants';
import { formatCurrency } from '@artemis/utils/currency-format';
import { PaymentSplitShape } from '../propTypes';

const PaymentSplitLabel = ({ paymentSplit, currencyCode }) => {
  const { paymentType, payPerPersonMicro } = paymentSplit;
  return (
    <>
      {paymentType === PAYMENT_SPLIT_TYPE.FIXED_AMOUNT && (
        <FormattedMessage
          entry="groupOrder.paymentSplitLabel.fixedAmount"
          values={{
            amount: formatCurrency({
              currencyCode,
              value: payPerPersonMicro,
            }),
          }}
        />
      )}
      {paymentType === PAYMENT_SPLIT_TYPE.PAY_SEPARATELY && (
        <FormattedMessage entry="groupOrder.paymentSplitLabel.paySeparately" />
      )}
    </>
  );
};

PaymentSplitLabel.propTypes = {
  paymentSplit: PaymentSplitShape.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

export default PaymentSplitLabel;
