import React from 'react';
import PropTypes from 'prop-types';
import { RtAccordion } from '@ritualco/ritual-frontend-components';
import { GuestSection } from './GuestSection';
import { GuestShape } from '../propTypes';

const GuestListProps = {
  currentUserExternalId: PropTypes.string.isRequired,
  guests: PropTypes.arrayOf(GuestShape).isRequired,
  isOrderSubmitted: PropTypes.bool.isRequired,
  merchantMenuUrl: PropTypes.string,
  onJoinAsGuest: PropTypes.func,
  onRemoveGuest: PropTypes.func,
  canRemoveSelf: PropTypes.bool,
};
const GuestList = ({
  currentUserExternalId,
  guests,
  isOrderSubmitted,
  merchantMenuUrl,
  onJoinAsGuest,
  onRemoveGuest,
  canRemoveSelf,
}) => {
  const renderGuests = () =>
    guests.map(guest => (
      <GuestSection
        key={guest.externalUserId}
        canRemoveSelf={canRemoveSelf}
        guest={guest}
        isCurrentUser={currentUserExternalId === guest.externalUserId}
        isOrderSubmitted={isOrderSubmitted}
        merchantMenuUrl={merchantMenuUrl}
        onJoinAsGuest={onJoinAsGuest}
        onRemoveGuest={onRemoveGuest}
      />
    ));

  return (
    <RtAccordion allowMultipleOpen allowAllClosed>
      {renderGuests()}
    </RtAccordion>
  );
};

GuestList.propTypes = GuestListProps;

export default GuestList;
