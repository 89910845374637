import PropTypes from 'prop-types';
import { PerkShape } from '@artemis/components/MenuGroup/propTypes';

export const CartItemShape = PropTypes.shape({
  quantity: PropTypes.number,
  title: PropTypes.string,
  priceMicro: PropTypes.number,
  price: PropTypes.string,
  note: PropTypes.string,
  cartItemOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      priceMicro: PropTypes.number,
    }),
  ),
  perk: PerkShape,
});

export const GroupOrderInfoShape = PropTypes.shape({
  isAdmin: PropTypes.bool,
  hostName: PropTypes.string,
});
